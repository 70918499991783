export default [
    {
        title: "Capítulos Survey",
        i18n: "Capítulos Survey",
    }
    /*
    {
        url: "/Capítulos",
        name: "Chapters",
        icon: "mdi mdi-note-multiple",
        i18n: "Chapters",
        index: 1,
        child: [
            {
            url: '/#',
            name: "Ranking",
            icon: "mdi mdi mdi-format-list-numbers",
            i18n: "Ranking",
            index: 1.1,
        }            
       ]
    }*/
]