<template>
	<div class="main-wrapper" :class="[mainWrapperClass]">
		<!---Navigation-->
		<Navbar  :logo="require('@/assets/images/logo/logo-arena.png')"  />
		<!---Sidebar-->
		<SideBar parent=".main-wrapper" :sidebarLinks="sidebarLinks"/>
		<!---Page Container-->
		<div class="main-container-fluid">
			<Breadcrumb />
			<transition name="router-anim" enter-active-class="fade-enter-active fade-enter" leave-active-class="fade-leave-active fade-enter">

				<router-view :key="$route.fullPath"></router-view>
			</transition>
			<!---Customizer / Settings
	<Customizer @updateTopbarColor="updateTopbarColor" />
		-->
		</div>	
	</div>
</template>

<script>

	import Navbar from '@/layout/full/header/ArenaBar.vue';
	import SideBar from '@/layout/full/sidebar/SideSurvey.vue';
	//import Customizer from '@/layout/full/customizer/Customizer.vue';
	import sidebarLinks from '@/layout/full/sidebar/sidesurveystructlinks.js';
	import Breadcrumb from '@/layout/full/breadcrumb/Breadcrumb.vue';
	import themeConfig from '@/../themeConfig.js';

	import { Surveys } from "../../services/Arena.js";
	const sv = new Surveys;
	
export default {
name: "MainContainer",
components: {
Navbar,
SideBar,
//Customizer,
Breadcrumb
},
data:() => ({
	topbarColor: themeConfig.topbarColor,
	logotitle: themeConfig.logotitle,
	sidebarLinks: sidebarLinks,
	SurveyStruct: [],
    ScoreTable: [],
		}),
		created() {
			//montar os links.

            alert("Container");

			var id = this.$store.state.SelectedSurvey;
			
			this.$vs.loading();
            //GetStruct GetScore
			
            sv.GetScore(id, 0).then((resp) => {
				this.SurveyStruct = resp.data.survey.chapters;
                this.ScoreTable = resp.data.survey.ScoreChapTable;
				this.$store.commit("SET_SurveyStruct", this.SurveyStruct);
                this.$store.commit("SET_SurveyScoreTable", this.ScoreTable);
				for (var i = 0; i < this.SurveyStruct.length; i++)
				{
                    //SurveyStruct.data.chapters
					var payload =
					{                        
                        url: `/surveys/struct2/${i}` ,
                        name: this.SurveyStruct[i].name,
						icon: "mdi mdi-note-multiple",
                        i18n: this.SurveyStruct[i].name,
						index: i + 1,
                        child: []
					};

					if (this.SurveyStruct[i].subChapters)
					{
						for (var j = 0; j < this.SurveyStruct[i].subChapters.length; j++)
						{
                            var payload2 =
                            {
                             //   url: `/SurveyCap/${i}/${j}`,
                                url: `/surveys/struct2/${i}/${j}`,
                                name: this.SurveyStruct[i].subChapters[j].name,
                                icon: "mdi mdi-bulletin-board",
                                i18n: this.SurveyStruct[i].subChapters[j].name,
                                index: i + 1
                            };

                            payload.child.push(payload2);
                        }
                    }
                    
                    sidebarLinks.push(payload);
                }

                this.$vs.loading.close();
            }, (err) => this.errors.push(err));

        },
methods: {
	updateTopbarColor(val) {
     this.topbarColor = val;
  }
},
computed: {
sidebarWidth: function() {
      return this.$store.state.sidebarWidth;
},
mainWrapperClass: function() {
      if(this.sidebarWidth == "default") {
        return "main-wrapper-default"
      } 
      else if(this.sidebarWidth == "mini") {return "main-wrapper-mini"}
      else if(this.sidebarWidth){ return "main-wrapper-full"}
      return "default"	
  },

},

}  	
</script>